import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import { updateQuote, saveQuote, sendGA } from '../../redux/actions'

import quote_1 from '../../image/quote/MainThemeIllustration.png'
import '../../carSelection.scss'

import packages from '../../data/info_packages.js'

class Package extends Component {
  constructor(props) {
    super(props)
    const quoteId = props.match.params.id
    this.state = {
      quoteId: quoteId,
      saved: false,
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)

    this.setState({
      isMobile: this.isMobile(),
    })
  }

  isMobile() {
    var userAgent = navigator.userAgent

    if (
      userAgent.match(
        /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
      ) != null ||
      userAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
      return true
    } else {
      return false
    }
  }

  async onPackageClick(_package) {
    this.props.quote.quote = {
      ...this.props.quote.quote,
      packages: _package,
    }

    this.props.updateQuote({
      _package,
    })

    this.props.saveQuote(this.props.quote)

    this.setState({
      saved: true,
    })

    sendGA({ category: 'Home L0', action: 'Complete Package: L0' })
  }

  componentDidUpdate() {
    if (this.props.quote.quote_id && this.state.saved) {
      this.props.history.push({
        pathname: `/shops/${this.props.quote.quote_id}`,
      })
    }
  }

  render() {
    const { isMobile } = this.state

    return (
      <div>
        <div className="container banner_container text-center">
          <h2 className="quote_title">내 차에 맞는 썬팅 패키지 상품을 골라보세요.</h2>
          <img className="quote_title_img" src={quote_1} alt="" />
        </div>

        <div className="container package_area">
          <div className="row">
            <div className="area_width_control">
              {packages.map((_package, index) => {
                return (
                  <div key={index}>
                    {!isMobile ? (
                      <div>
                        <img className="package_img" src={_package.image[0]} alt="" />
                        <div
                          className="package_button package_btn_blue"
                          onClick={() => {
                            this.onPackageClick(_package)
                          }}
                        >
                          상담으로 혜택받기
                        </div>
                      </div>
                    ) : (
                      <div>
                        <img className="package_img_mobile" src={_package.image[1]} alt="" />
                        <div
                          className="package_button package_btn_blue package_btn_mobile"
                          onClick={() => {
                            this.onPackageClick(_package)
                          }}
                        >
                          상담으로 혜택받기
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...state,
  }
}

export default compose(
  firestoreConnect(),
  connect(mapStateToProps, {
    saveQuote,
    updateQuote,
  }),
)(Package)
