import React from 'react'
import PropTypes from 'prop-types'

import { getCarInfoType, getPriceKey, SunroofType } from './utils'

import low from '../../image/product/low.png'

const Line3 = ({ Info, Car, Products, ProductStatus, OnUpdate }) => {
  const lineName = [
    {
      index: 0,
      key: 'front',
      display: '전면',
      on: 1,
    }, //0
    {
      index: 1,
      key: 'all',
      display: '측후면',
      on: 1,
    }, //1
    {
      index: 8,
      key: 'sunroof_normal',
      display: '썬루프',
      on: 1,
    }, //8
  ]

  const sunroofType = SunroofType[Car.sunroof]
  if (sunroofType) {
    lineName[2].on = 1
    lineName[2].key = sunroofType
    lineName[2].display = Car.sunroof
  } else {
    lineName[2].on = 0
  }

  const selectedLines = lineName.filter(ln => ln.on).map(x => x.index)

  const productMap = Products.reduce((pre, cur) => {
    pre[cur.id] = cur
    return pre
  }, {})

  const onSelectQeote = index => {
    const productStatus = ProductStatus.slice()
    productStatus[index] = {
      ...productStatus[index],
      is_open: !productStatus[index].is_open,
    }
    OnUpdate({
      product_status: productStatus,
    })
  }

  const onDeleteRow = index => {
    const productStatus = ProductStatus.slice()
    let info = Info
    const prices = {
      ...info.prices,
    }
    if (index >= 1 && index <= 7) {
      ;[1, 2, 3, 4, 5, 6, 7].forEach(i => {
        productStatus[i] = {
          selected: false,
          is_open: false,
        }
      })
    } else {
      productStatus[index] = {
        selected: false,
        is_open: false,
      }
    }

    if (info.prices && info.prices[index]) {
      delete prices[index]
    }

    info = {
      ...info,
      prices: {
        ...prices,
      },
      sum_price: Object.keys(prices).length
        ? Object.keys(prices)
            .map(i => prices[i].value)
            .reduce((x, y) => x + y)
        : 0,
    }

    OnUpdate({
      product_status: productStatus,
      info: info,
    })
  }

  const onSelectVal = (index, val, lineupKey) => {
    const productStatus = ProductStatus.slice()
    productStatus[index] = {
      ...productStatus[index],
      is_open: !productStatus[index].is_open,
      lineup: productMap[val].lineup,
      product: productMap[val].product,
    }
    const info = {
      ...Info,
      type: getCarInfoType(productStatus, selectedLines),
    }

    // set price
    const priceKey = getPriceKey(Car, lineupKey, info.type)

    info.prices = {
      ...info.prices,
      [index]: {
        value: productMap[val][priceKey],
      },
    }

    info.sum_price = Object.keys(info.prices).reduce((pre, index) => {
      pre += info.prices[index].value
      return pre
    }, 0)

    OnUpdate({
      product_status: productStatus,
      info: info,
    })
  }

  return (
    <div className="tint_select_table_wrap tint_select_table_wrap_6">
      <table className="tint_select_table">
        <colgroup>
          <col width="90" />
          <col />
          <col width="30" />
        </colgroup>
        <tbody>
          {lineName
            .filter(({ on }) => on)
            .map(({ index, display, key }) => (
              <tr key={index} className={ProductStatus[index].selected ? 'show' : ''}>
                <td>{display}</td>
                <td>
                  <div className={'quote_select ' + (ProductStatus[index].is_open ? 'is-open' : '')}>
                    <span className="quote_select_title" name="line_up" onClick={() => onSelectQeote(index)}>
                      {ProductStatus[index].lineup || '라인업'}
                    </span>
                    <ul>
                      {Products.map(product => (
                        <li key={product.id} name={product.id} onClick={() => onSelectVal(index, product.id, key)}>
                          {product.lineup}
                        </li>
                      ))}
                    </ul>
                    <input type="hidden" name="changemetoo" />
                  </div>
                </td>
                <td>
                  <div onClick={() => onDeleteRow(index)}>
                    <img src={low} alt="" />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

Line3.propTypes = {
  Car: PropTypes.object.isRequired,
  Products: PropTypes.array.isRequired,
  ProductStatus: PropTypes.array.isRequired,
  OnUpdate: PropTypes.func.isRequired,
}
export default Line3
