import React from 'react'
import Window from './Window'
import Line from './Line'

const Car = ({ Info, Car, Products, ProductStatus, onUpdate, onSubmit }) => {
  const OnSelectAll = () => {
    onUpdate({
      product_status: Array(9).fill({
        selected: true,
        is_open: false,
      }),
    })
  }

  const OnSelectReset = () => {
    onUpdate({
      product_status: Array(9).fill({
        selected: false,
        is_open: false,
      }),
      info: {},
    })
  }

  const lineComponent = (
    <Line Info={Info} Car={Car} Products={Products} ProductStatus={ProductStatus} OnUpdate={onUpdate} />
  )
  const windowComponent = <Window Info={Info} Car={Car} ProductStatus={ProductStatus} OnUpdate={onUpdate} />

  const is_okay_proceed = !Info.sum_price || parseInt(Info.sum_price) === 0 ? false : true

  return (
    <div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="tint_title">1. 차량창문을 선택해주세요</div>
          {windowComponent}
          <div className="tint_select_btn clearfix hidden-xs">
            <button className="btn btn_black btn_lg" name="all_select" onClick={OnSelectAll}>
              전체선택
            </button>
            <button className="btn btn_transparent" onClick={OnSelectReset}>
              선택초기화
            </button>
          </div>
          <div className="tint_select_btn clearfix hidden-sm">
            <a className="btn btn_black btn_lg" href="#next_step" style={{ width: '100%' }}>
              다음단계로
            </a>
          </div>
        </div>
        <div className="col-12 col-lg-6" id="next_step">
          <div className="tint_title">2. 제품라인을 선택해주세요</div>
          {lineComponent}
          <div className="tint_select_btn clearfix hidden-xs" style={{ maxWidth: '500px', marginBottom: 0 }}>
            <button
              className="btn btn_blue btn_lg btn_position"
              name="quote_ok"
              href=""
              onClick={() => {
                if (!is_okay_proceed) return
                onSubmit()
              }}
              style={{ opacity: is_okay_proceed ? 1 : 0.3 }}
            >
              견적 가격 확인
            </button>
          </div>
        </div>
      </div>

      <div className="tint_select_btn clearfix hidden-sm tint_sum_fixed" style={{ maxWidth: '500px', marginBottom: 0 }}>
        <button
          className="btn btn_blue btn_lg btn_position_mobile"
          href=""
          name="quote_ok"
          onClick={() => {
            if (!is_okay_proceed) return
            onSubmit()
          }}
          style={{ opacity: is_okay_proceed ? 1 : 0.3 }}
        >
          견적 가격 확인
        </button>
      </div>
    </div>
  )
}

export default Car
