import React from 'react'
import PropTypes from 'prop-types'

import tint_car_2 from '../../image/tint/tint_car_2.svg'
import tint_car from '../../image/tint/tint_car.svg'
import tint_car_6 from '../../image/tint/tint_car_6.svg'
import { SunroofType } from './utils'

const Window3 = ({ Info, Car, ProductStatus: ProductStatusOri, OnUpdate }) => {
  let ProductStatus = [...ProductStatusOri]
  let info = { ...Info }
  const prices = {
    ...info.prices,
  }
  const showSunroof = SunroofType[Car.sunroof]
  const arraySideBack = [1, 2, 3, 4, 5, 6, 7]

  if (ProductStatus[1].selected) {
    arraySideBack.forEach(i => {
      ProductStatus[i] = ProductStatus[1]
    })
  }

  const makeNewProductStatus = (productStatus, index) => {
    if (!productStatus[index].selected) {
      productStatus[index] = {
        ...productStatus[index],
        selected: !productStatus[index].selected,
      }
    } else {
      productStatus[index] = {
        selected: false,
        is_open: false,
      }

      if (info.prices && info.prices[index]) {
        delete prices[index]
      }

      info = {
        ...info,
        prices: {
          ...prices,
        },
        sum_price: Object.keys(prices).length
          ? Object.keys(prices)
              .map(i => prices[i].value)
              .reduce((x, y) => x + y)
          : 0,
      }
    }

    return productStatus
  }

  const onSelectWindow = index => {
    let productStatus = ProductStatus.slice()

    if (arraySideBack.indexOf(index) > -1) {
      arraySideBack.forEach(i => {
        productStatus = makeNewProductStatus(productStatus, i)
      })
    } else {
      productStatus = makeNewProductStatus(productStatus, index)
    }

    OnUpdate({
      product_status: productStatus,
      info,
    })
  }

  if (Car.side_windows === 2) {
    return (
      <div className="tint_select_area">
        <svg className="tint_svg_window" x="0px" y="0px" width="429px" height="460px" viewBox="0 0 429 460">
          <g fill="none" fillRule="evenodd" className="window_all">
            {/* <!-- front --> */}
            <path
              id="window_tint_link_1"
              d="M276.334,123.5c-17.5-7-62.834-8.667-62.834-8.667s-45.334,1.667-62.834,8.667s-13.499,10.667-12.166,20.333S151.167,207.5,153.167,214s5.166,12,17.333,12s43,0,43,0s30.833,0,43,0s15.333-5.5,17.333-12s13.334-60.5,14.667-70.167S293.834,130.5,276.334,123.5z"
              className={ProductStatus[0].selected ? 'active' : ''}
            />

            {/* <!-- panorama --> */}
            <path
              id="window_tint_link_2"
              style={{ display: showSunroof ? 'block' : 'none' }}
              d="M267.168,317.334c0,0,0-57.667,0-67s-9.834-9.834-9.834-9.834H213.5h-43.834c0,0-9.834,0.501-9.834,9.834s0,67,0,67S158.5,328.5,171.333,328.5s42.167,0,42.167,0s29.334,0,42.167,0S267.168,317.334,267.168,317.334z"
              className={ProductStatus[8].selected ? 'active' : ''}
            />

            {/* <!-- left --> */}
            <path
              id="window_tint_link_3"
              d="M123.334,191.5c0,0-0.5-4.833,2.375-4.833s2.875,2.5,3.375,4.125s12.583,49.375,12.583,56.708s-0.333,25-1.667,37s-4.833,31.418-4.833,31.418s-0.75,3.25-3.25,3.25s-2.833-4.75-2.833-4.75L123.334,191.5z"
              className={ProductStatus[1].selected ? 'active' : ''}
            />

            {/* <!-- right --> */}
            <path
              id="window_tint_link_4"
              d="M303.717,191.5c0,0,0.5-4.833-2.375-4.833s-2.875,2.5-3.375,4.125s-12.582,49.375-12.582,56.708s0.333,25,1.666,37s4.834,31.418,4.834,31.418s0.75,3.25,3.25,3.25s2.832-4.75,2.832-4.75L303.717,191.5z"
              className={ProductStatus[2].selected ? 'active' : ''}
            />

            {/* <!-- back --> */}
            <path
              id="window_tint_link_5"
              d="M272.417,368.75c-1.458-2.083-7.792-5.375-19.292-2.75s-39.625,2.625-39.625,2.625s-28.125,0-39.625-2.625s-17.834,0.667-19.292,2.75s-2.416,4-1.333,13s7.249,32.499,9.833,38.166s7.418,9.833,20.834,11.75s24.167,2.25,29.583,2.25s16.167-0.333,29.583-2.25s18.25-6.083,20.834-11.75s8.75-29.166,9.833-38.166S273.875,370.833,272.417,368.75z"
              className={ProductStatus[7].selected ? 'active' : ''}
            />
          </g>

          <g fill="none" fillRule="evenodd">
            <g transform="translate(-5 230)">
              <path d="M139.512 20.5H99.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="40" y="29">
                  운전1
                </tspan>
              </text>
            </g>
            <g transform="translate(292 230)">
              <path d="M40.512 20.5H.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="50" y="29">
                  조수1
                </tspan>
              </text>
            </g>
            <text
              className="text_tint_link_hover"
              id="text_tint_link_1"
              transform="translate(195 150)"
              fill="#000"
              fontSize="20"
              fontWeight="bold"
            >
              <tspan x="0" y="26">
                전면
              </tspan>
            </text>
            <text
              className="text_tint_link_hover"
              id="text_tint_link_2"
              transform="translate(177 260)"
              fill="#000"
              fontSize="20"
              fontWeight="bold"
              style={{ display: showSunroof ? 'block' : 'none' }}
            >
              <tspan x={Car.sunroof === '썬루프' ? 7.5 : 0.5} y="26">
                {Car.sunroof}
              </tspan>
            </text>
            <text
              className="text_tint_link_hover"
              id="text_tint_link_5"
              transform="translate(195 380)"
              fill="#000"
              fontSize="20"
              fontWeight="bold"
            >
              <tspan x="0" y="26">
                후면
              </tspan>
            </text>
          </g>
        </svg>
        <div className="tint_svg_map">
          <img src={tint_car_2} alt="" useMap="#tint_2_map" />
          <map name="tint_2_map" id="tint_2_map">
            <area
              id="tint_link_1"
              alt="front"
              coords="138,131,213,115,290,131,270,225,157,225"
              shape="poly"
              onClick={() => onSelectWindow(0)}
            />
            <area
              id="tint_link_2"
              alt="panorama"
              style={{ display: showSunroof ? 'block' : 'none' }}
              coords="160,240,263,240,263,325,160,325"
              shape="poly"
              onClick={() => onSelectWindow(8)}
            />
            <area
              id="tint_link_3"
              alt="left1"
              coords="125,186,140,247,131,322,127,270,28,270,28,233,125,233"
              shape="poly"
              onClick={() => onSelectWindow(1)}
            />
            <area
              id="tint_link_4"
              alt="right1"
              coords="301,186,301,233,396,233,396,270,300,270,296,322,283,247"
              shape="poly"
              onClick={() => onSelectWindow(2)}
            />
            <area
              id="tint_link_5"
              alt="back"
              coords="153,365,272,365,258,430,167,430"
              shape="poly"
              onClick={() => onSelectWindow(7)}
            />
          </map>
        </div>
      </div>
    )
  } else if (Car.side_windows === 4) {
    return (
      <div className="tint_select_area">
        <svg className="tint_svg_window" x="0px" y="0px" width="429px" height="460px" viewBox="0 0 429 460">
          {/* window */}
          <g fill="none" fillRule="evenodd" className="window_all">
            {/* front */}
            <path
              id="window_tint_link_1"
              d="M215.4,177.994c40.953,0,65.32,16.589,65.32,16.589l12.891-38.81c11.24-38.81-63.512-36.881-79.154-36.881 c-15.721,0-90.16-1.929-78.92,36.881l12.892,38.81C148.35,194.583,174.368,177.994,215.4,177.994z"
              className={ProductStatus[0].selected ? 'active' : ''}
            />
            {/* root */}
            <path
              style={{ display: showSunroof ? 'block' : 'none' }}
              id="window_tint_link_2"
              d="M214.85,265.8c35.137,0,46.691-11.265,47.556-21.836l1.022-31.789c0.393-4.629-3.065-8.564-8.019-10.339 c0,0-15.563-5.864-40.952-5.864c-25.39,0-40.875,5.864-40.875,5.864c-4.873,1.774-8.332,5.632-8.018,10.339l1.021,31.789 C167.451,254.535,179.713,265.8,214.85,265.8z"
              className={ProductStatus[8].selected ? 'active' : ''}
            />
            {/* left1 */}
            <path
              id="window_tint_link_3"
              d="M153.224,234.011c0-1.696-0.235-3.472-0.708-5.169l-15.484-58.64c-0.394-1.312-1.965-1.852-3.145-1.003 l-1.809,1.466c-1.178,0.926-1.807,2.315-1.729,3.781l4.48,101.154c0.078,1.002,1.258,1.543,2.043,0.926l15.643-12.809 c0.708-0.54,1.022-1.389,1.022-2.238L153.224,234.011z"
              className={ProductStatus[1].selected ? 'active' : ''}
            />
            {/* left2 */}
            <path
              id="window_tint_link_4"
              d="M135.381,287.48l2.908,65.045c0.078,2.314,3.301,2.932,4.322,0.771l9.983-21.836 c1.257-2.701,1.808-5.557,1.808-8.488l-0.551-45.908c0-3.008-3.615-4.551-5.895-2.701l-10.455,8.643 C136.088,284.162,135.302,285.783,135.381,287.48z"
              className={ProductStatus[3].selected ? 'active' : ''}
            />
            {/* right1 */}
            <path
              id="window_tint_link_5"
              d="M275.768,234.011c0-1.696,0.236-3.472,0.707-5.17l15.486-58.639c0.393-1.312,1.965-1.852,3.145-1.003 l1.808,1.466c1.179,0.926,1.808,2.315,1.729,3.781L294.161,275.6c-0.078,1.002-1.257,1.543-2.043,0.926l-15.644-12.809 c-0.707-0.541-1.021-1.389-1.021-2.238L275.768,234.011z"
              className={ProductStatus[2].selected ? 'active' : ''}
            />
            {/* right2 */}
            <path
              id="window_tint_link_6"
              d="M293.611,286.4l-2.908,65.045c-0.079,2.623-3.695,3.24-4.795,0.924l-9.668-21.295 c-1.102-2.391-1.65-4.938-1.572-7.561l0.629-51.078c0-1.158,1.336-1.697,2.2-1.004l14.935,12.268 C293.219,284.395,293.689,285.398,293.611,286.4z"
              className={ProductStatus[4].selected ? 'active' : ''}
            />
            {/* back */}
            <path
              id="window_tint_link_7"
              d="M214.85,422.352c12.655,0,23.424-2.236,30.813-4.783c5.109-1.773,8.568-6.404,8.805-11.65 l2.986-67.666c0.157-3.936-2.986-7.178-6.996-7.178c0,0-27.118,0-36.079,0c-8.882,0-35.608,0-35.608,0 c-4.008,0-7.152,3.242-6.995,7.178l2.987,67.666c0.235,5.246,3.693,9.877,8.803,11.65 C190.718,420.115,202.115,422.352,214.85,422.352z"
              className={ProductStatus[7].selected ? 'active' : ''}
            />
          </g>

          {/* text */}
          <g fill="none" fillRule="evenodd">
            <g transform="translate(2 205)">
              <path d="M139.512 20.5H99.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="40" y="29">
                  운전1
                </tspan>
              </text>
            </g>
            <g transform="translate(286 205)">
              <path d="M40.512 20.5H.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="50" y="29">
                  조수1
                </tspan>
              </text>
            </g>
            <g transform="translate(284 288)">
              <path d="M40.512 20.5H.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="50" y="29">
                  조수2
                </tspan>
              </text>
            </g>
            <g transform="translate(4 288)">
              <path d="M139.512 20.5H99.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="40" y="29">
                  운전2
                </tspan>
              </text>
            </g>
            <text
              className="text_tint_link_hover"
              id="text_tint_link_1"
              transform="translate(195 130)"
              fill="#000"
              fontSize="20"
              fontWeight="bold"
            >
              <tspan x="0" y="26">
                전면
              </tspan>
            </text>
            <text
              className="text_tint_link_hover"
              id="text_tint_link_2"
              style={{ display: showSunroof ? 'block' : 'none' }}
              transform="translate(185 210)"
              fill="#000"
              fontSize="20"
              fontWeight="bold"
            >
              <tspan x={Car.sunroof === '썬루프' ? 0.5 : -6.5} y="26">
                {Car.sunroof}
              </tspan>
            </text>
            <text
              className="text_tint_link_hover"
              id="text_tint_link_7"
              transform="translate(195 350)"
              fill="#000"
              fontSize="20"
              fontWeight="bold"
            >
              <tspan x="0" y="26">
                후면
              </tspan>
            </text>
          </g>
        </svg>
        <div className="tint_svg_map">
          <img src={tint_car} alt="" useMap="#tint_4_map" />
          <map name="tint_4_map" id="tint_4_map">
            <area
              id="tint_link_1"
              name="car_front"
              alt="front"
              coords="161,121,271,121,295,143,280,194,215,178,149,194,135,143"
              shape="poly"
              onClick={() => onSelectWindow(0)}
            />
            <area
              id="tint_link_2"
              alt="sunroof"
              style={{ display: showSunroof ? 'block' : 'none' }}
              coords="166,202,264,202,257,260,168,260"
              shape="poly"
              onClick={() => onSelectWindow(8)}
            />
            <area
              id="tint_link_3"
              alt="left1"
              coords="131,171,135,167,153,231,153,261,134,275,133,242,37,242,37,208,131,208"
              shape="poly"
              onClick={() => onSelectWindow(1)}
            />
            <area
              id="tint_link_4"
              alt="left2"
              coords="135,285,153,272,153,327,140,354,137,325,37,325,37,294,136,294"
              shape="poly"
              onClick={() => onSelectWindow(3)}
            />
            <area
              id="tint_link_5"
              alt="right1"
              coords="292,167,298,171,297,208,390,208,390,242,296,242,292,275,275,261,275,231"
              shape="poly"
              onClick={() => onSelectWindow(2)}
            />
            <area
              id="tint_link_6"
              alt="right2"
              coords="275,272,291,285,293,294,390,294,390,325,292,325,288,354,275,327"
              shape="poly"
              onClick={() => onSelectWindow(4)}
            />
            <area
              id="tint_link_7"
              alt="back"
              coords="170,330,257,330,251,415,175,415"
              shape="poly"
              onClick={() => onSelectWindow(7)}
            />
          </map>
        </div>
      </div>
    )
  } else {
    return (
      <div className="tint_select_area">
        <svg className="tint_svg_window" x="0px" y="0px" width="429px" height="460px" viewBox="0 0 429 460">
          {/* <!-- window --> */}
          <g fill="none" fillRule="evenodd" className="window_all">
            {/* <!-- front --> */}
            <path
              id="window_tint_link_1"
              d="M295.531,99.833c-3.167-5.167-22.75-10.75-30.167-12.5s-29.5-6.583-51-6.583s-43.583,4.833-51,6.583s-27,7.333-30.167,12.5s1.25,9.167,2,10.583s19.5,27.75,19.5,27.75s3,3.583,13,1.667s29.917-4.917,46.667-4.917s36.667,3,46.667,4.917s13-1.667,13-1.667s18.75-26.333,19.5-27.75S298.698,105,295.531,99.833z"
              className={ProductStatus[0].selected ? 'active' : ''}
            />
            {/* <!-- roof --> */}
            <path
              id="window_tint_link_2"
              style={{ display: showSunroof ? 'block' : 'none' }}
              d="M264.105,349.833c0,0,0-152.333,0-164s-10.333-11.167-10.333-11.167h-39.406h-39.408c0,0-10.333-0.5-10.333,11.167s0,164,0,164s-0.168,9.334,10.166,9.334s39.574,0,39.574,0s29.24,0,39.574,0S264.105,349.833,264.105,349.833z"
              className={ProductStatus[8].selected ? 'active' : ''}
            />

            {/* <!-- left1 --> */}
            <path
              id="window_tint_link_3"
              d="M135.375,128.542c0,0-1.166-2.125-2.958-1.333s-1.584,2.5-1.584,3.292s-0.008,89.875-0.008,89.875s-0.106,1.609,0.644,2.094c0.75,0.485,2.203-0.563,2.641-0.922c0.438-0.359,12.813-10.156,12.813-10.156s1.495-1.141,1.495-3.641s0-56.5,0-56.5s0.333-3-1.167-5.625S135.375,128.542,135.375,128.542z"
              className={ProductStatus[1].selected ? 'active' : ''}
            />
            {/* <!-- left2 --> */}
            <path
              id="window_tint_link_4"
              d="M148.417,223.292c0,0,0.229-1.583-0.459-2.021c-0.688-0.438-1.832,0.417-2.166,0.792s-13.563,11.5-13.563,11.5s-1.402,1.297-1.402,3.563s0,74.392,0,74.392s-0.046,1.094,0.438,1.483s1.672-0.483,2.234-0.875c0.562-0.392,13.344-9.938,13.344-9.938s1.573-0.78,1.573-3.438C148.416,296.094,148.417,223.292,148.417,223.292z"
              className={ProductStatus[3].selected ? 'active' : ''}
            />
            {/* <!-- left3 --> */}
            <path
              id="window_tint_link_5"
              d="M148.417,314.291c0,0,0.229-1.582-0.459-2.02c-0.688-0.438-1.832,0.416-2.166,0.791s-13.563,9.5-13.563,9.5s-1.402,1.297-1.402,3.563s0,61.434,0,61.434s-0.044,1.092,0.438,1.483c0.797,0.646,1.672-0.483,2.234-0.875c0.562-0.392,13.344-15.938,13.344-15.938s1.573-0.78,1.573-3.438S148.417,314.291,148.417,314.291z"
              className={ProductStatus[5].selected ? 'active' : ''}
            />
            {/* <!-- right1 --> */}
            <path
              id="window_tint_link_6"
              d="M293.625,128.542c0,0,1.166-2.125,2.958-1.333s1.584,2.5,1.584,3.292s0.008,89.875,0.008,89.875s0.105,1.609-0.645,2.094c-0.75,0.485-2.202-0.563-2.641-0.922c-0.438-0.359-12.813-10.156-12.813-10.156s-1.495-1.141-1.495-3.641s0-56.5,0-56.5s-0.333-3,1.167-5.625S293.625,128.542,293.625,128.542z"
              className={ProductStatus[2].selected ? 'active' : ''}
            />
            {/* <!-- right2 --> */}
            <path
              id="window_tint_link_7"
              d="M280.583,223.292c0,0-0.229-1.583,0.459-2.021c0.688-0.438,1.832,0.417,2.166,0.792s13.563,11.5,13.563,11.5s1.403,1.297,1.403,3.563s0,74.392,0,74.392s0.045,1.094-0.439,1.483c-0.483,0.39-1.672-0.483-2.233-0.875c-0.563-0.392-13.344-9.938-13.344-9.938s-1.573-0.78-1.573-3.438C280.584,296.094,280.583,223.292,280.583,223.292z"
              className={ProductStatus[4].selected ? 'active' : ''}
            />
            {/* <!-- right3 --> */}
            <path
              id="window_tint_link_8"
              d="M280.583,314.291c0,0-0.229-1.582,0.459-2.02c0.688-0.438,1.832,0.416,2.166,0.791s13.563,9.5,13.563,9.5s1.403,1.297,1.403,3.563s0,61.434,0,61.434s0.044,1.092-0.439,1.483c-0.797,0.646-1.672-0.483-2.233-0.875c-0.563-0.392-13.344-15.938-13.344-15.938s-1.573-0.78-1.573-3.438S280.583,314.291,280.583,314.291z"
              className={ProductStatus[6].selected ? 'active' : ''}
            />

            {/* <!-- back --> */}
            <path
              id="window_tint_link_9"
              d="M285.74,398.876c0,0-16.729-20.646-19.125-23.042c-2.396-2.396-5.833-2.334-5.833-2.334h-46.418h-46.418c0,0-3.438-0.063-5.833,2.334c-2.395,2.396-19.125,23.042-19.125,23.042s-1.708,1.75-0.458,3.75s3.833,1.5,3.833,1.5l68.001,0.056l68.001-0.056c0,0,2.583,0.5,3.833-1.5S285.74,398.876,285.74,398.876z"
              className={ProductStatus[7].selected ? 'active' : ''}
            />
          </g>

          {/* <!-- text --> */}
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 155)">
              <path d="M139.512 20.5H99.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="40" y="29">
                  운전1
                </tspan>
              </text>
            </g>
            <g transform="translate(288 155)">
              <path d="M40.512 20.5H.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="50" y="29">
                  조수1
                </tspan>
              </text>
            </g>
            <g transform="translate(288 247)">
              <path d="M40.512 20.5H.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="50" y="29">
                  조수2
                </tspan>
              </text>
            </g>
            <g transform="translate(1 247)">
              <path d="M139.512 20.5H99.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="40" y="29">
                  운전2
                </tspan>
              </text>
            </g>
            <g transform="translate(288 327)">
              <path d="M40.512 20.5H.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="50" y="29">
                  조수3
                </tspan>
              </text>
            </g>
            <g transform="translate(1 327)">
              <path d="M139.512 20.5H99.5" stroke="#000" opacity="0.5" strokeLinecap="circle"></path>
              <text fontSize="20" fontWeight="bold" fill="#000">
                <tspan x="40" y="29">
                  운전3
                </tspan>
              </text>
            </g>
            <text
              className="text_tint_link_hover"
              id="text_tint_link_1"
              transform="translate(195 90)"
              fill="#000"
              fontSize="20"
              fontWeight="bold"
            >
              <tspan x="0" y="26">
                전면
              </tspan>
            </text>
            <text
              className="text_tint_link_hover"
              id="text_tint_link_2"
              transform="translate(185 250)"
              fill="#000"
              fontSize="20"
              fontWeight="bold"
              style={{ display: showSunroof ? 'block' : 'none' }}
            >
              <tspan x={Car.sunroof === '썬루프' ? 0.5 : -6.5} y="26">
                {Car.sunroof}
              </tspan>
            </text>
            <text
              className="text_tint_link_hover"
              id="text_tint_link_9"
              transform="translate(195 370)"
              fill="#000"
              fontSize="20"
              fontWeight="bold"
            >
              <tspan x="0" y="26">
                후면
              </tspan>
            </text>
          </g>
        </svg>
        {/* <!-- map --> */}
        <div className="tint_svg_map">
          <img src={tint_car_6} alt="" useMap="#tint_6_map" />
          <map name="tint_6_map" id="tint_6_map">
            <area
              id="tint_link_1"
              alt="front"
              coords="131,66,296,66,272,140,157,140"
              shape="poly"
              onClick={() => onSelectWindow(0)}
            />
            <area
              id="tint_link_2"
              alt="sunroof"
              coords="166,176,261,176,261,357,166,357"
              shape="poly"
              style={{ display: showSunroof ? 'block' : 'none' }}
              onClick={() => onSelectWindow(8)}
            />
            <area
              id="tint_link_3"
              alt="left1"
              coords="131,127,148,149,148,209,131,222,131,192,33,192,33,158,131,158"
              shape="poly"
              onClick={() => onSelectWindow(1)}
            />
            <area
              id="tint_link_4"
              alt="left2"
              coords="131,235,148,221,148,301,131,313,131,284,33,284,33,250,131,250"
              shape="poly"
              onClick={() => onSelectWindow(3)}
            />
            <area
              id="tint_link_5"
              alt="left3"
              coords="131,324,148,311,148,370,131,389,131,364,33,364,33,330,131,330"
              shape="poly"
              onClick={() => onSelectWindow(5)}
            />
            <area
              id="tint_link_6"
              alt="right1"
              coords="280,149,298,127,298,158,390,158,390,192,298,192,298,222,280,209"
              shape="poly"
              onClick={() => onSelectWindow(2)}
            />
            <area
              id="tint_link_7"
              alt="right2"
              coords="280,221,298,235,298,250,390,250,390,284,298,284,298,313,280,301"
              shape="poly"
              onClick={() => onSelectWindow(4)}
            />
            <area
              id="tint_link_8"
              alt="right3"
              coords="280,311,298,324,298,330,390,330,390,364,298,364,298,389,280,370"
              shape="poly"
              onClick={() => onSelectWindow(6)}
            />
            <area
              id="tint_link_9"
              alt="back"
              coords="165,374,263,374,387,424,141,424"
              shape="poly"
              onClick={() => onSelectWindow(7)}
            />
          </map>
        </div>
      </div>
    )
  }
}

Window3.propTypes = {
  ProductStatus: PropTypes.array.isRequired,
  OnUpdate: PropTypes.func.isRequired,
}
export default Window3
