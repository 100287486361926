import { SEARCH_SHOPE, SET_SELECTED_STORE_INDEX, SET_SELECTED_STORE, SET_VERTEX_STORES } from '../actionTypes'
import store from '../store'

export const getShopes = (query, local) => {
  var shops = store.getState().shope.vertexStores

  if (query) {
    return shops.filter(({ name, address }) => {
      if (local) {
        return (name.includes(query) || address.includes(query)) && (name.includes(local) || address.includes(local))
      } else {
        return name.includes(query) || address.includes(query)
      }
    })
  } else {
    return shops.filter(({ name, address }) => {
      // If exclude string exists
      if (Array.isArray(local)) {
        return (
          (name.includes(query) || address.includes(query)) &&
          (name.includes(local[0]) || address.includes(local[0])) &&
          !address.includes(local[1])
        )
      }
      return name.includes(local) || address.includes(local)
    })
  }
}

export const getShopsUsingLocation = (lat, long) => {
  var shops = store.getState().shope.vertexStores

  const deg2rad = deg => {
    return (deg * Math.PI) / 180
  }
  const rad2deg = rad => {
    return (rad * 180) / Math.PI
  }

  const calcDistance = (lat1, long1, lat2, long2) => {
    var theta = long1 - long2
    var dist =
      Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2)) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.cos(deg2rad(theta))
    dist = Math.acos(dist)
    dist = rad2deg(dist)
    dist = dist * 60 * 1.1515
    dist = dist * 1.609344
    return Number(dist * 1000).toFixed(2)
  }

  var distObject = []
  shops.forEach(shop => {
    var shopName = shop.name
    var shopLat = shop.latitude
    var shopLong = shop.longitude
    var dist = calcDistance(lat, long, shopLat, shopLong)

    distObject[shopName] = parseInt(dist)
    shop.dist = parseInt(dist)
  })

  const sortKey = Object.keys(distObject).sort(function(a, b) {
    return distObject[a] - distObject[b]
  })
  const sortObject = []

  sortKey.some((key, index) => {
    sortObject[key] = distObject[key]
    if (sortObject[key] / 1000 > 20 || index === 10 - 1) return true
    return false
  })

  return shops
    .filter(({ name }) => {
      return sortObject[name] ? true : false
    })
    .sort(function(a, b) {
      return a.dist - b.dist
    })
}

export const getStoreUsingMapBounds = bounds => {
  var shops = store.getState().shope.vertexStores

  const ea = bounds.ea
  const la = bounds.la
  const ja = bounds.ja
  const ka = bounds.ka

  return shops.filter(({ latitude, longitude }) => {
    return longitude >= ea && ja >= longitude && latitude >= la && ka >= latitude
  })
}

const oneStore = {
  address: '서울시 강남구 도산대로38길 11 (논현동)',
  dist: 0,
  id: 'se010000010',
  latitude: '37.519950',
  longitude: '127.032323',
  name: '강남 논현점',
}

const initialState = {
  shopes: [oneStore],
  query: '',
  local: '',
  checked: false,
  allowGeolocation: false,
  index: -1,
  selectedStore: null,
  vertexStores: [oneStore],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_SHOPE:
      return {
        ...state,
        ...action.payload,
      }
    case 'GEOLOCATION_STATUS':
      return {
        ...state,
        allowGeolocation: action.allowGeolocation,
      }
    case SET_SELECTED_STORE_INDEX:
      return {
        ...state,
        index: action.index,
      }
    case SET_SELECTED_STORE:
      return {
        ...state,
        selectedStore: action.selectedStore,
      }
    case SET_VERTEX_STORES:
      return {
        ...state,
        shopes: action.vertexStores,
        vertexStores: action.vertexStores,
      }
    default:
      return {
        ...state,
      }
  }
}
