import React, { Component } from 'react'
import ProductMobile from '../Product/mobile'
import ProductDesktop from '../Product/desktop'

class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: false,
    }
  }
  async componentDidMount() {
    this.setState({
      isMobile: this.isMobile(),
    })
  }

  isMobile() {
    var userAgent = navigator.userAgent

    if (
      userAgent.match(
        /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
      ) != null ||
      userAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
      return true
    } else {
      return false
    }
  }
  render() {
    if (!this.state.isMobile) {
      return (
        <div>
          <ProductDesktop></ProductDesktop>
        </div>
      )
    } else {
      return (
        <div>
          <ProductMobile></ProductMobile>
        </div>
      )
    }
  }
}

export default Product
