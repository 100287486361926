export default [
  //product_name: "전면창", "측후면", "썬루프"
  {
    product_code: 'B-B',
    product_name: ['비잔티', '비잔티', ''],
    product_price: '1283100',
    image: [require('../image/quote/packages_desktop/bb.png'), require('../image/quote/packages_mobile/bb_m.png')],
  },
  {
    product_code: 'B-B-B',
    product_name: ['비잔티', '비잔티', '비잔티'],
    product_price: '1634100',
    image: [require('../image/quote/packages_desktop/bbb.png'), require('../image/quote/packages_mobile/bbb_m.png')],
  },
  {
    product_code: 'B-T(P)-T(P)',
    product_name: ['비잔티', '파워/TNT', '파워/TNT'],
    product_price: '1361100',
    image: [
      require('../image/quote/packages_desktop/btptp.png'),
      require('../image/quote/packages_mobile/btptp_m.png'),
    ],
  },
  {
    product_code: 'B-T(P)-X',
    product_name: ['비잔티', '파워/TNT', ''],
    product_price: '971100',
    image: [require('../image/quote/packages_desktop/btpx.png'), require('../image/quote/packages_mobile/btpx_m.png')],
  },
  {
    product_code: 'T(P)-T(P)-T(P)',
    product_name: ['파워/TNT', '파워/TNT', '파워/TNT'],
    product_price: '1244100',
    image: [
      require('../image/quote/packages_desktop/tptptp.png'),
      require('../image/quote/packages_mobile/tptptp_m.png'),
    ],
  },
  {
    product_code: 'L-L-L',
    product_name: ['래티튜드', '래티튜드', '래티튜드'],
    product_price: '1244100',
    image: [require('../image/quote/packages_desktop/lll.png'), require('../image/quote/packages_mobile/lll_m.png')],
  },
  {
    product_code: 'T(P)-T(P)-X',
    product_name: ['파워/TNT', '파워/TNT', ''],
    product_price: '971100',
    image: [
      require('../image/quote/packages_desktop/tptpx.png'),
      require('../image/quote/packages_mobile/tptpx_m.png'),
    ],
  },
  {
    product_code: 'X-T(P)-T(P)',
    product_name: ['', '파워/TNT', '파워/TNT'],
    product_price: '971100',
    image: [
      require('../image/quote/packages_desktop/xtptp.png'),
      require('../image/quote/packages_mobile/xtptp_m.png'),
    ],
  },
  {
    product_code: 'L-L-X',
    product_name: ['래티튜드', '래티튜드', ''],
    product_price: '971100',
    image: [require('../image/quote/packages_desktop/llx.png'), require('../image/quote/packages_mobile/llx_m.png')],
  },
  {
    product_code: 'X-T(P)-X',
    product_name: ['', '파워/TNT', ''],
    product_price: '581100',
    image: [require('../image/quote/packages_desktop/xtpx.png'), require('../image/quote/packages_mobile/xtpx_m.png')],
  },
  {
    product_code: 'L-A-A',
    product_name: ['래티튜드', '아티젠', '아티젠'],
    product_price: '971100',
    image: [require('../image/quote/packages_desktop/laa.png'), require('../image/quote/packages_mobile/laa_m.png')],
  },
  {
    product_code: 'L-A-X',
    product_name: ['래티튜드', '아티젠', ''],
    product_price: '776100',
    image: [require('../image/quote/packages_desktop/lax.png'), require('../image/quote/packages_mobile/lax_m.png')],
  },
]
