import React, { Component } from 'react'
import Guide from '../Guide'
import Guide2 from '../Guide2'
import { loadProductsDB } from '../../data'
import Car from '../Car'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { isLoaded } from 'react-redux-firebase'

import { saveQuote, updateQuote, sendGA } from '../../redux/actions'

class Tint extends Component {
  constructor(props) {
    super(props)

    const quoteId = props.match.params.id
    this.state = {
      quoteId: quoteId,
      saved: false,
      isSelected: false,
    }
    this.onConfirm = this.onConfirm.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.OnModifyQuote = this.OnModifyQuote.bind(this)
    // this.productGuideChat = this.productGuideChat.bind(this)
  }

  onUpdate(updatedData) {
    this.props.updateQuote(updatedData)
  }

  onConfirm() {
    this.props.saveQuote(this.props.quote)
    this.setState({
      saved: true,
    })
    sendGA({ category: 'Quote', action: 'Complete Product Selection' })
  }

  OnModifyQuote() {
    if (this.props.quotes && this.state.quoteId && !this.props.quote.quote_id) {
      const quote = {
        ...this.props.quotes[this.state.quoteId],
        quote_id: this.state.quoteId,
      }
      this.props.updateQuote(quote)
    }
  }

  // Removed this feature
  /*async productGuideChat() {
    this.props.saveQuote(this.props.quote)

    this.setState({
      isSelected: true,
      saved: true,
    })

    await zendeskShow()
    await zendeskSendInTint(this.props.quote)
    sendGA({ category: 'Quote', action: 'Start Product Guide Chat' })
  }*/

  componentDidMount() {
    this.OnModifyQuote()

    window.jQuery(document).scroll(function() {
      var y = window.jQuery(this).scrollTop()
      if (y > 15) {
        window.jQuery('.tint_sum_fixed').addClass('show')
      } else {
        window.jQuery('.tint_sum_fixed').removeClass('show')
      }
    })

    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
    if (this.props.quote.quote_id && this.state.saved) {
      if (this.state.isSelected) {
        this.props.history.push(`/consulting/chat/${this.props.quote.quote_id}`)
      } else {
        this.props.history.push({
          pathname: `/shops/${this.props.quote.quote_id}`,
        })
      }
    }
    this.OnModifyQuote()
  }

  render() {
    return (
      <div>
        {this.props.match.params.id && !isLoaded(this.props.quotes && this.props.quote.quote) ? (
          ''
        ) : (
          <div>
            <div className="container tint_container">
              <Guide2 title="제품가이드" />
              <Guide title="견적가이드" />
              <Car
                Info={this.props.quote.quote.info}
                Car={this.props.quote.quote.car}
                // Quote={}
                Products={this.props.products}
                ProductStatus={this.props.quote.quote.product_status}
                onUpdate={this.onUpdate.bind(this)}
                onSubmit={this.onConfirm.bind(this)}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const products = loadProductsDB({
    type: 'vertex',
  })

  return {
    ...state,
    products: products,
    quote: state.quote,
    quotes: state.firestore.data && state.firestore.data.quotes,
  }
}

export default compose(
  firestoreConnect(props => {
    if (!props.match.params.id) return []
    return [{ collection: 'quotes', doc: props.match.params.id }]
  }),
  connect(mapStateToProps, {
    saveQuote,
    updateQuote,
  }),
)(Tint)
