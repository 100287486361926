import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getStoreInMapBounds, setSelectedStore } from '../../redux/actions'

class Map extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info_windows: [],
      markers: [],
      map: null,
      activation_flag: false,
    }
  }

  clearInfoWindows(index) {
    this.state.info_windows.forEach((info_window, i) => {
      if (i !== index) {
        info_window.close()
      }
    })
    this.state.info_windows[index].open(this.state.map, this.state.markers[index])
  }

  clearInfoAllWindows() {
    this.state.info_windows.forEach(info => {
      info.close()
    })
  }

  updateMap() {
    // 모바일이면 업데이트 하지 않음
    var userAgent = navigator.userAgent
    if (
      userAgent.match(
        /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
      ) != null ||
      userAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
      return
    }

    const me = this

    const container = document.getElementById('map') //지도를 담을 영역의 DOM 레퍼런스
    const options = {
      //지도를 생성할 때 필요한 기본 옵션
      center: new window.daum.maps.LatLng(37.5018, 127.0048), //지도의 중심좌표.
      level: 7, //지도의 레벨(확대, 축소 정도)
    }
    let map

    if (this.state.map) {
      map = this.state.map

      this.state.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.state.info_windows.forEach(info_window => {
        info_window.close()
      })
    } else {
      map = new window.daum.maps.Map(container, options) //지도 생성 및 객체 리턴
      var mapTypeControl = new window.daum.maps.MapTypeControl()
      map.addControl(mapTypeControl, window.daum.maps.ControlPosition.TOPRIGHT)

      var zoomControl = new window.daum.maps.ZoomControl()
      map.addControl(zoomControl, window.daum.maps.ControlPosition.RIGHT)
    }

    let info_windows = []
    let markers = []

    Promise.all(
      this.props.Locations.map(async (shope, index) => {
        const markerImage = new window.daum.maps.MarkerImage(
          require('../../image/quote/blueMapMarker.svg'),
          new window.daum.maps.Size(36, 42),
          { offset: new window.daum.maps.Point(18, 42) },
        )
        const marker = new window.daum.maps.Marker({
          image: markerImage,
          map: map, // 마커를 표시할 지도
          position: new window.daum.maps.LatLng(shope.latitude, shope.longitude), // 마커의 위치
        })
        markers.push(marker)

        const infowindow = new window.daum.maps.InfoWindow({
          content: `<div class="info_window">${shope.name}</div>`, // 인포윈도우에 표시할 내용
          zIndex: 119,
        })
        info_windows.push(infowindow)

        window.daum.maps.event.addListener(marker, 'mouseover', me.OnMakeOverListener(map, marker, infowindow))
        window.daum.maps.event.addListener(marker, 'mouseout', me.OnMakeOutListener(infowindow, index))
        window.daum.maps.event.addListener(marker, 'click', () => {
          this.props.onSelectShope(index)
        })
        window.daum.maps.event.addListener(map, 'idle', () => me.buttonHandler())

        var points = []
        var bounds = new window.daum.maps.LatLngBounds()
        var i
        for (i = 0; i < Object.keys(this.props.Locations).length; i++) {
          //Add LatLng objects to the array points that have latitude and longitude data from the shops searched
          points.push(new window.daum.maps.LatLng(this.props.Locations[i].latitude, this.props.Locations[i].longitude))
          //Add points to LatLngBounds object
          bounds.extend(points[i])
        }
        // ip targeting, 지역 버튼을 누를 때, 검색을 했을 때는 마커가 있는 곳으로 지도 변경
        //To change the level and center of the map
        // if (this.props.local || this.props.query) {
        //   map.setBounds(bounds)
        // }
        map.setBounds(bounds)
      }),
    )

    this.setState({
      info_windows,
      markers,
      map,
      activation_flag: false,
    })

    this.props.onUpdate({
      map: map,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.Locations !== this.props.Locations) {
      this.updateMap()
    }
    if (this.state.map && this.state.markers[this.props.index] && this.state.info_windows[this.props.index]) {
      this.clearInfoWindows(this.props.index)
    } else {
      this.clearInfoAllWindows()
    }
  }

  componentDidMount() {
    this.updateMap()
  }

  OnMakeOverListener = (map, marker, infowindow) => {
    return () => {
      infowindow.open(map, marker)
    }
  }

  OnMakeOutListener = (infowindow, index) => {
    return () => {
      if (this.props.currentStoreIndex !== index) infowindow.close()
    }
  }

  buttonHandler() {
    this.setState({
      activation_flag: true,
    })
  }

  searchThisArea() {
    window.scrollTo(0, 0)
    this.props.setSelectedStore(null)
    var els = document.querySelectorAll('button.selected_button')

    for (var i = 0; i < els.length; i++) {
      els[i].classList.toggle('selected_button', false)
    }

    let map
    if (this.state.map) {
      map = this.state.map
      this.props.getStoreInMapBounds(map.getBounds())
    }

    this.setState({
      activation_flag: false,
    })
  }

  render() {
    return (
      <div>
        <div>
          {this.state.activation_flag ? (
            <button className="search_this_area_button" onClick={() => this.searchThisArea()}>
              이 지역에서 재검색
            </button>
          ) : null}
        </div>
        <div id="map" style={{ width: '100%' }} />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...state,
    local: state.shope.local,
    index: state.shope.index,
    query: state.shope.query,
    selectedStore: state.shope.selectedStore,
  }
}
export default compose(
  connect(mapStateToProps, {
    getStoreInMapBounds,
    setSelectedStore,
  }),
)(Map)
