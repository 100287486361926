import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import { loadCarDB, getGroupByData, getfilteredData } from '../../data'

import { setHomePath, updateQuote, clearQuote } from '../../redux/actions'

import quote_1 from '../../image/quote/MainThemeIllustration.png'

import '../../carSelection.scss'

import { sendGA } from '../../redux/actions'

class Home_3 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        origin: getGroupByData(this.props.cars, 'origin'),
        maker: [],
        model: [],
        sunroof: ['없음', '썬루프', '파노라마'],
        origin_open: false,
        maker_open: false,
        model_open: false,
        sunroof_open: false,
      },
    }
  }

  componentDidMount() {
    this.props.clearQuote()
    window.scrollTo(0, 0)

    this.props.setHomePath(this.props.location.pathname)
  }

  onChange = (key, value, nextGroupKey) => {
    const options = this.state.options
    // const { sunroof } = this.props.car
    const newQuote = {
      ...this.props.car,
      [key]: value,
    }
    let newOpenState = {
      origin_open: false,
      maker_open: false,
      model_open: false,
      sunroof_open: false,
    }

    if (key === 'origin') {
      newQuote['maker'] = ''
      newQuote['model'] = ''
      newOpenState.maker_open = true
    }
    if (key === 'maker') {
      newQuote['model'] = ''
      newQuote['sunroof'] = ''
      newOpenState.model_open = true
    }
    if (key === 'model') {
      newOpenState.sunroof_open = true
    }

    if (nextGroupKey) {
      const filteredOptionData = getfilteredData(this.props.cars, {
        origin: newQuote['origin'],
        maker: newQuote['maker'],
        model: newQuote['model'],
        sunroof: newQuote['sunroof'],
        [key]: value,
      })
      options[nextGroupKey] = getGroupByData(filteredOptionData, nextGroupKey)
    }
    this.setState({
      ...newOpenState,
      options: options,
    })
    this.props.updateQuote({
      car: {
        ...newQuote,
      },
    })
  }

  onReset = () => {
    this.props.clearQuote()
  }

  onSearch = () => {
    const car = this.props.quote.quote.car
    const no_car_exist = !car.side_windows

    if (!no_car_exist) {
      sendGA({ category: 'Home L3', action: 'Complete Car: L3' })

      this.props.history.push({
        pathname: '/tint',
      })
    }
  }

  toggleDropboxStatus(type) {
    const { state } = this
    const _type = !state[type]
    const _state = {
      maker_open: false,
      model_open: false,
      origin_open: false,
      sunroof_open: false,
      year_open: false,
    }
    _state[type] = _type
    this.setState(_state)
  }

  render() {
    const { car } = this.props
    const no_car_exist = !car.side_windows
    // const maker_target = this.props.car.origin ? { 'data-target': '#select_maker_modal' } : {}
    // const model_target = this.props.car.origin && this.props.car.maker ? { 'data-target': '#select_model_modal' } : {}

    return (
      <div>
        <div className="container banner_container text-center">
          <h2 className="quote_title">
            안녕하세요? <br /> 현재 이용하고 계시는 차량을 선택해주세요.
          </h2>
          <img className="quote_title_img" src={quote_1} alt="" />
        </div>

        <div className="container quote_container">
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="quote_select" name="quote_select">
                <div
                  className={(this.state.origin_open ? 'quote_bottom_border ' : '') + 'quote_select_title '}
                  onClick={() => {
                    this.toggleDropboxStatus('origin_open')
                    this.onReset()
                  }}
                >
                  {car.origin || '01. 원산지를 선택해 주세요'}
                </div>
              </div>
              <div
                style={{ display: this.state.origin_open ? '' : 'none' }}
                className="carSelection_dropbox quote_select_title"
              >
                <div className="carSelection_dropbox_inner">
                  <div className="carSelection_dropbox_inner_box">
                    {this.state.options.origin.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => this.onChange('origin', item, 'maker')}
                          className="carSelection_dropbox_item"
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="quote_select">
                <div
                  className={(this.state.maker_open ? 'quote_bottom_border ' : '') + 'quote_select_title '}
                  onClick={() => this.toggleDropboxStatus('maker_open')}
                >
                  {car.maker || '02. 제조사를 선택해 주세요'}
                </div>
              </div>
              <div style={{ display: this.state.maker_open ? '' : 'none' }} className="carSelection_dropbox">
                <div className="carSelection_dropbox_inner">
                  <div className="carSelection_dropbox_inner_box">
                    {this.state.options.maker.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => this.onChange('maker', item, 'model')}
                          className="carSelection_dropbox_item"
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="quote_select">
                <div
                  className={(this.state.model_open ? 'quote_bottom_border ' : '') + 'quote_select_title '}
                  onClick={() => this.toggleDropboxStatus('model_open')}
                >
                  {car.model || '03. 모델명을 선택해 주세요'}
                </div>
              </div>
              <div style={{ display: this.state.model_open ? '' : 'none' }} className="carSelection_dropbox">
                <div className="carSelection_dropbox_inner">
                  <div className="carSelection_dropbox_inner_box">
                    {this.state.options.model.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => this.onChange('model', item)}
                          className="carSelection_dropbox_item"
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="quote_select ">
                <div
                  className={(this.state.sunroof_open ? 'quote_bottom_border ' : '') + 'quote_select_title '}
                  onClick={() => this.toggleDropboxStatus('sunroof_open')}
                >
                  {car.sunroof || '04. 썬루프를 선택해 주세요'}
                </div>
              </div>
              <div style={{ display: this.state.sunroof_open ? '' : 'none' }} className="carSelection_dropbox">
                <div className="carSelection_dropbox_inner">
                  <div className="carSelection_dropbox_inner_box">
                    {this.state.options.sunroof.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => this.onChange('sunroof', item)}
                          className="carSelection_dropbox_item"
                        >
                          {item}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-150">
              <button className="btn btn_transparent float-left pl-0" onClick={this.onReset}>
                선택초기화
              </button>
              <button
                className="btn btn_blue btn_lg float-right"
                style={{ opacity: no_car_exist ? 0.3 : 1 }}
                onClick={this.onSearch}
              >
                내 차에 맞는 제품 고르기
              </button>
            </div>
          </div>
        </div>

        <div className="container my-150">{/* <!-- 검색 결과물 위치 -->  */}</div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...state,
    quote: state.quote,
    car: state.quote.quote.car,
    cars: loadCarDB(),
  }
}
export default compose(
  firestoreConnect(),
  connect(mapStateToProps, {
    updateQuote,
    clearQuote,
    setHomePath,
  }),
)(Home_3)
