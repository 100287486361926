import {
  SAVE_QUOTE,
  UPDATE_QUOTE,
  GET_QUOTE,
  GET_CHAT_TOKEN,
  SEARCH_SHOPE,
  CLEAR_QUOTE,
  SET_HOMEPATH,
  SET_SELECTED_STORE_INDEX,
  SET_SELECTED_STORE,
  SET_VERTEX_STORES,
} from './actionTypes'

import { loadCarDB } from '../data'
import { getShopes, getShopsUsingLocation, getStoreUsingMapBounds } from './reducers/shope'
import { quote as quoteScheme } from './scheme'
import store from './store'

import { sendSlack, sendCouponCodeToSlack, getParticipatedStores, sendAlertToSlack } from '../utils/megcenter'

export const sendNabusScript = (quoteId, type) => {
  return
  // const {
  //   quote: { info, product_status },
  // } = store.getState().quote

  // // 채팅 위젯 안에서의 전환점 잡을 때 quote_id가져오기 id or null(tint)
  // if (quoteId === -1) {
  //   quoteId = store.getState().quote.quote_id
  // }

  // let _11h11mConv = {}
  // let convDetName = []
  // let convDetCost = []
  // let convDetCnt = []
  // let convDetCategory = []

  // if (product_status[0].lineup) {
  //   convDetName.push(`전면-${product_status[0].lineup}`)
  //   convDetCost.push(info.prices[0].value)
  //   convDetCnt.push('1')
  //   convDetCategory.push('vertex')
  // }
  // if (product_status[1].lineup) {
  //   convDetName.push(`측후면-${product_status[1].lineup}`)
  //   convDetCost.push(info.prices[1].value)
  //   convDetCnt.push('1')
  //   convDetCategory.push('vertex')
  // }
  // if (product_status[8].lineup) {
  //   convDetName.push(`썬루프-${product_status[8].lineup}`)
  //   convDetCost.push(info.prices[8].value)
  //   convDetCnt.push('1')
  //   convDetCategory.push('vertex')
  // }

  // _11h11mConv['convTypeId'] = type
  // _11h11mConv['convKey'] = quoteId ? quoteId : ''
  // _11h11mConv['convCa'] = info.sum_price ? info.sum_price : ''
  // _11h11mConv['convDetName'] = convDetName.join(';')
  // _11h11mConv['convDetCost'] = convDetCost.join(';')
  // _11h11mConv['convDetCnt'] = convDetCnt.join(';')
  // _11h11mConv['convDetCategory'] = convDetCategory.join(';')

  // try {
  //   window._11h11m.log(_11h11mConv, 'conv')
  //   // console.log(_11h11mConv)
  // } catch (e) {}
}

export const sendGA = data => {
  return
  // const {
  //   quote,
  //   quote: { product_status },
  // } = store.getState().quote
  // let custom_dims = {}

  // if (data.category === 'Home L0') {
  //   custom_dims = {
  //     ...custom_dims,
  //     value: quote.packages.product_price,
  //     product_front: quote.packages.product_name[0],
  //     product_sideback: quote.packages.product_name[1],
  //     product_sunroof: quote.packages.product_name[2],
  //     total_price: quote.packages.product_price,
  //   }
  // }

  // if (data.category === 'After Quote' && data.action === 'Get Phone Number') {
  //   custom_dims = {
  //     ...custom_dims,
  //     car_origin: quote.car.origin,
  //     car_maker: quote.car.maker,
  //     car_model: quote.car.model,
  //     car_sunroof: quote.car.sunroof,
  //   }

  //   if (product_status[0].lineup) {
  //     custom_dims = {
  //       ...custom_dims,
  //       product_front: product_status[0].lineup,
  //     }
  //   }
  //   if (product_status[1].lineup) {
  //     custom_dims = {
  //       ...custom_dims,
  //       product_sideback: product_status[1].lineup,
  //     }
  //   }
  //   if (product_status[8].lineup) {
  //     custom_dims = {
  //       ...custom_dims,
  //       product_sunroof: product_status[8].lineup,
  //     }
  //   }
  // }

  // // Quote 또는 After Quote 인 경우
  // if (data.category === 'Quote' || data.category === 'After Quote') {
  //   custom_dims = {
  //     ...custom_dims,
  //     car_origin: quote.car.origin,
  //     car_maker: quote.car.maker,
  //     car_model: quote.car.model,
  //     car_sunroof: quote.car.sunroof,
  //   }

  //   // Quote - Complete Product Selection
  //   if (data.action === 'Complete Product Selection') {
  //     if (product_status[0].lineup) {
  //       custom_dims = {
  //         ...custom_dims,
  //         product_front: product_status[0].lineup,
  //       }
  //     }
  //     if (product_status[1].lineup) {
  //       custom_dims = {
  //         ...custom_dims,
  //         product_sideback: product_status[1].lineup,
  //       }
  //     }
  //     if (product_status[8].lineup) {
  //       custom_dims = {
  //         ...custom_dims,
  //         product_sunroof: product_status[8].lineup,
  //       }
  //     }
  //     // 총견적금액
  //     if (quote.store_info) {
  //       custom_dims = {
  //         ...custom_dims,
  //         value: quote.info.sum_price,
  //         total_price: quote.info.sum_price,
  //       }
  //     }

  //     // VWO Goal #1 전송
  //     window.VWO = window.VWO || []
  //     window.VWO.push(['track.revenueConversion', quote.info.sum_price])
  //   }

  //   // Quote - Complete Budget Selection
  //   if (data.action === 'Complete Budget Selection') {
  //     custom_dims = {
  //       ...custom_dims,
  //       value: quote.budget.value,
  //       total_price: quote.budget.value,
  //     }
  //   }
  // }

  // if (data.category === 'After Quote') {
  //   if (product_status[0].lineup) {
  //     custom_dims = {
  //       ...custom_dims,
  //       product_front: product_status[0].lineup,
  //     }
  //   }
  //   if (product_status[1].lineup) {
  //     custom_dims = {
  //       ...custom_dims,
  //       product_sideback: product_status[1].lineup,
  //     }
  //   }
  //   if (product_status[8].lineup) {
  //     custom_dims = {
  //       ...custom_dims,
  //       product_sunroof: product_status[8].lineup,
  //     }
  //   }

  //   // After Quote - Complete Request, Start Quote Chat
  //   if (
  //     data.action === 'Start Consulting' ||
  //     data.action === 'Complete Request' ||
  //     data.action === 'Start Quote Chat'
  //   ) {
  //     if (quote.store_info) {
  //       custom_dims = {
  //         ...custom_dims,
  //         selected_store: quote.store_info.name,
  //         // ? quote.store_info.name : "대전 테크노점"
  //       }
  //     }
  //   }

  //   // After Quote - Complete Request
  //   if (data.action === 'Complete Request') {
  //     if (quote.phone) {
  //       if (Object.keys(quote.packages).length !== 0) {
  //         custom_dims = {
  //           ...custom_dims,
  //           product_front: quote.packages.product_name[0],
  //           product_sideback: quote.packages.product_name[1],
  //           product_sunroof: quote.packages.product_name[2],
  //           total_price: quote.packages.product_price,
  //           phone: quote.phone,
  //         }
  //       } else if (quote.budget.value !== 0) {
  //         custom_dims = {
  //           ...custom_dims,
  //           phone: quote.phone,
  //           total_price: quote.budget.value,
  //         }
  //       } else {
  //         custom_dims = {
  //           ...custom_dims,
  //           phone: quote.phone,
  //           total_price: quote.info.sum_price,
  //         }
  //       }
  //     }
  //   }
  // }

  // // console.log(data.category,  " - ", data.action)
  // // console.log(custom_dims)
  // window.gtag('event', data.action, {
  //   event_category: data.category,
  //   ...custom_dims,
  // })
}

const getCarFromQuote = quote => {
  const { origin, maker, model, sunroof } = quote.car
  let car = {
    origin,
    maker,
    model,
    sunroof,
  }
  if (origin && maker && model && sunroof) {
    const loadCar = loadCarDB({
      origin: origin,
      maker: maker,
      model: model,
    })

    if (loadCar[0]) {
      car = loadCar[0]
    }

    if (car.side_windows) {
      car.sunroof = sunroof
    }
  }

  return car
}

export const updateQuote = data => (dispatch, getState) => {
  const state = getState()
  const quote = {
    ...state.quote.quote,
    ...data,
  }
  quote.car = getCarFromQuote(quote)
  // TODO: set info

  // TODO: set car and info, price in there
  return dispatch({
    type: UPDATE_QUOTE,
    payload: {
      ...quote,
    },
  })
}

export const saveQuote = quote => (dispatch, getState, { getFirestore }) => {
  const state = getState()
  const firestore = getFirestore()
  const uid = state.firebase.auth.uid
  // remove quote_id in quote
  const quoteId = quote.quote_id

  // QuoteScheme에 따라서 필요 없는 필드들 제거
  const newQuote = Object.keys(quoteScheme).reduce((pre, key) => {
    pre[key] = quote.quote[key]
    return pre
  }, {})

  newQuote.product_status = newQuote.product_status.map(ps => {
    if (ps.selected && !ps.lineup) {
      ps.selected = false
    }
    return ps
  })

  if (quoteId) {
    newQuote.updatedAt = new Date()
    newQuote.owner_id = uid
    return firestore
      .collection('quotes')
      .doc(quoteId)
      .update(newQuote)
      .then(docRef => {
        dispatch({
          type: SAVE_QUOTE,
          payload: {
            ...quote,
            quote: newQuote,
            quote_id: quoteId,
          },
        })
      })
  } else {
    newQuote.createdAt = newQuote.updatedAt = new Date()
    newQuote.owner_id = newQuote.owner_id || uid
    return firestore
      .collection('quotes')
      .add(newQuote)
      .then(docRef => {
        dispatch({
          type: SAVE_QUOTE,
          payload: {
            ...quote,
            quote: newQuote,
            quote_id: docRef.id,
          },
        })
        // sendNabusScript(docRef.id, "E4494C01")
      })
  }
}

export const getQuote = quoteId => (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore()
  return firestore
    .collection('quotes')
    .doc(quoteId)
    .get()
    .then(doc => {
      dispatch({
        type: GET_QUOTE,
        payload: {
          ...doc.data(),
        },
      })
    })
}

export const getChatToken = () => (dispatch, getState, { getFirestore }) => {
  dispatch({
    type: GET_CHAT_TOKEN,
    token: 'TEST_TOKEN',
  })
}

export const setSelectedStoreIndex = index => dispatch => {
  return dispatch({
    type: SET_SELECTED_STORE_INDEX,
    index: index,
  })
}
export const setSelectedStore = index => dispatch => {
  return dispatch({
    type: SET_SELECTED_STORE,
    selectedStore: index,
  })
}
export const searchShope = (query, local) => dispatch => {
  const shopes = getShopes(query, local)
  dispatch({
    type: SEARCH_SHOPE,
    payload: {
      shopes: shopes,
      query: query,
      local: local,
      index: -1,
    },
  })
}

export const getStoreNearLocation = (lat, long, local) => dispatch => {
  const shops = getShopsUsingLocation(lat, long)
  dispatch({
    type: SEARCH_SHOPE,
    payload: {
      shopes: shops,
      local: local,
      index: -1,
      query: '',
    },
  })
}

export const getStoreInMapBounds = bounds => dispatch => {
  const shops = getStoreUsingMapBounds(bounds)
  dispatch({
    type: SEARCH_SHOPE,
    payload: {
      shopes: shops,
      local: '',
      index: -1,
      query: '',
    },
  })
}

export const clearQuote = () => ({
  type: CLEAR_QUOTE,
})

export const setHomePath = path => dispatch => {
  return dispatch({
    type: SET_HOMEPATH,
    homePath: path,
  })
}

export const consultingReqToSlack = data => {
  return sendSlack(data).catch(e => {
    console.log(e)
  })
}

export const alertToSlack = data => {
  if (window.location.host === 'tint.riderdash.com') {
    return sendAlertToSlack(data).catch(e => {
      console.log(e)
    })
  }
  return Promise.resolve()
}

export const couponCodeToSlack = data => {
  return sendCouponCodeToSlack(data).catch(e => {
    console.log(e)
  })
}

export const setGeolocationStatus = status => dispatch => {
  return dispatch({
    type: 'GEOLOCATION_STATUS',
    allowGeolocation: status,
  })
}

export const setVertexStores = data => dispatch => {
  return dispatch({
    type: SET_VERTEX_STORES,
    vertexStores: data,
  })
}

function isKorean(ch) {
  var numUnicode = ch.charCodeAt(0)
  if (44032 <= numUnicode && numUnicode <= 55203) return true
  return false
}

export const getAndSetParcipatedShops = async () => {
  const vertexStore = await getParticipatedStores()
    .then(result => {
      let participatedStores = result.data
      participatedStores = participatedStores.filter(
        item => item.vertex_yn === 'Y' && item.id !== 'se090000002' && item.id !== 'se010000011',
      )

      participatedStores.sort((a, b) => {
        // 한글을 우선순위로 정렬
        if (isKorean(a.name[0]) && !isKorean(b.name[0])) {
          return -1
        }
        if (!isKorean(a.name[0]) && isKorean(b.name[0])) {
          return 1
        }
        // 일반적인 이름순 정렬
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      const stores = participatedStores.map(item => ({
        id: item.id,
        name: item.name,
        address: `${item.address_1} ${item.address_2}`,
        latitude: item.latitude,
        longitude: item.longitude,
        dist: 0,
      }))
      return stores
    })
    .catch(err => {
      console.log(err)
    })

  store.dispatch(setVertexStores(vertexStore))
}
