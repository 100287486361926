export const quote = {
  car: {
    origin: '',
    model: '',
    maker: '',
    sunroof: '',
  },
  info: {},
  product_status: Array(9).fill({
    selected: false,
    is_open: false,
  }),
  phone: '',
  opt_in_phone_call: false,
  store_info: {},
  budget: { value: 0, isSelected: false },
  packages: {},
}
