import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import promo_1 from '../../image/promotion/promo_1.jpg'

import { sendGA } from '../../redux/actions'

class Promotion extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div className="container banner_container">
          <div className="row">
            <div className="col-12 col-md-6 order-md-12 px-0">
              <img className="d-block w-100" src={promo_1} alt="" />
            </div>
            <div className="col-12 col-md-6 order-md-1">
              <div className="d-table w-100 h-100">
                <div className="d-table-cell align-middle">
                  <p className="headline">
                    측 후면 시공 시<br />
                    필름제거 무료
                  </p>
                  <p className="sub">
                    <br />
                    라이더대쉬에서 윈도우 틴팅 시공 시,
                    <br />
                    기존에 시공했던 필름은 무료로 제거해드립니다!
                    <br />
                  </p>
                  <div className="quote_btn mt-30">
                    <Link
                      to="/"
                      className="btn btn_blue"
                      onClick={() => sendGA({ category: 'Promotion', action: 'Start Quote' })}
                    >
                      온라인 견적내기
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container my-150">
          <table className="lluma_table promo_table mx-auto d-none d-xl-table">
            <colgroup>
              <col width="" />
              <col width="" />
              <col width="200" />
              <col width="200" />
              <col width="200" />
              <col width="200" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="2" />
                <th className="td_gray_1">전면/썬루프 제거비용</th>
                <th>할인 적용가</th>
                <th className="td_gray_1">측 후면유리 제거비용</th>
                <th>할인 적용가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">국내차량</td>
                <td className="text-left">SEDAN/소형SUV</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td />
                <td className="text-left">SUV</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td />
                <td className="text-left">VAN</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="text-left">수입차량</td>
                <td className="text-left">SEDAN/소형SUV</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td />
                <td className="text-left">SUV</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td />
                <td className="text-left">VAN</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
          </table>

          <table className="lluma_table lluma_table_m promo_table mx-auto d-xl-none">
            <colgroup>
              <col width="" />
              <col width="80" />
              <col width="" />
              <col width="80" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="4">국내차량 · SEDAN/소형SUV</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">국내차량 · SUV</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">국내차량 · VAN</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">수입차량 · SEDAN/소형SUV</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">수입차량 · SUV</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="4">수입차량 · VAN</th>
              </tr>
              <tr>
                <th className="td_gray_1">
                  전면/썬루프
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
                <th className="td_gray_1">
                  측 후면유리
                  <br />
                  제거비용
                </th>
                <th>할인가</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
              <tr>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
                <td className="td_gray_2">20,000원</td>
                <td>무료</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default Promotion
