import React, { Component } from 'react'
import Clearness from '../../../image/product/clearness.svg'
import Number_1 from '../../../image/product/number_1.svg'
import Science from '../../../image/product/science.svg'
import UV_block from '../../../image/product/uv_block.svg'
import How_To_Pick from '../../../image/product/how-to-pick.png'

import '../mobile/index.scss'

const premium_tb_row1 = [
  '[제조사] 제품명',
  '[루마] 버텍스 1100',
  '[루마] 버텍스 900',
  '[루마] 버텍스 700',
  '[루마] 버텍스 650',
  '[루마] 버텍스 600',
  '[루마] 버텍스 500',
]
const premium_tb_row2 = [
  '추천 시공 부위',
  '전체 적용 가능',
  '전체 적용 가능',
  '전체 적용 가능',
  '측·후면/파노라마썬루프',
  '측·후면/파노라마썬루프',
  '측·후면/파노라마썬루프',
]
const premium_tb_row3 = [
  '필름 컬러',
  '로얄블랙',
  '버텍스차콜',
  '모던블랙사파이어',
  '이그조틱메탈',
  '다이아몬드블랙',
  '블랙사파이어',
]
const premium_tb_row4 = ['보증 기간', '10년', '10년', '7년', '7년', '7년', '5년']
const premium_tb_row5 = ['금속성분여부', '비금속', '비금속', '비금속', '금속포함', '금속포함', '금속포함']
const premium_tb_row6 = ['GPS 간섭유무', '영향없음', '영향없음', '영향없음', '약간영향', '초미세', '초미세']
const premium_tb_row7 = ['하이패스 (IR 타입)', '영향있음', '영향있음', '영향없음', '영향없음', '영향없음', '영향없음']
const premium_tb_row8 = ['하이패스 (RF 타입)', '영향없음', '영향없음', '영향없음', '약간영향', '약간영향', '약간영향']
const premium_tb_row9 = ['라디오 글라스 안테나', '영향없음', '영향없음', '영향없음', '약간영향', '약간영향', '약간영향']
const premium_tb_row10 = ['RF 주파수 간섭', '영향없음', '영향없음', '영향없음', '약간영향', '약간영향', '약간영향']
const premium_tb_row11 = ['스마트폰 와이파이', '영향없음', '영향없음', '영향없음', '약간영향', '약간영향', '약간영향']
const premium_tb_row12 = ['전파간섭유무', '전파장애 0%', '전파장애 0%', '전파장애 0%', '영향있음', '영향있음', '초미세']
const premium_tb_row13 = ['터널 무아레', '해당없음', '해당없음', '해당없음', '미세', '미세', '초미세']

const premium_tb = [
  premium_tb_row1,
  premium_tb_row2,
  premium_tb_row3,
  premium_tb_row4,
  premium_tb_row5,
  premium_tb_row6,
  premium_tb_row7,
  premium_tb_row8,
  premium_tb_row9,
  premium_tb_row10,
  premium_tb_row11,
  premium_tb_row12,
  premium_tb_row13,
]

const best_tb_row1 = [
  '[제조사] 제품명',
  '[루마] 비잔티',
  '[루마] 래티튜드',
  '[루마] TNT',
  '[루마] 파워',
  '[루마] 아티젠',
]
const best_tb_row2 = [
  '추천 시공 부위',
  '전체 적용 가능',
  '전체 적용 가능',
  '측·후면/파노라마썬루프',
  '측·후면/파노라마썬루프',
  '측·후면/파노라마썬루프',
]
const best_tb_row3 = ['필름 컬러', '티타늄그레이/그리너리', '차콜', '크롬미러실버', '티파니블랙반사', '블랙']
const best_tb_row4 = ['보증 기간', '7년', '7년', '7년', '7년', '5년']
const best_tb_row5 = ['금속성분여부', '금속/비금속 (35,50% 만)', '비금속', '비금속', '금속포함', '금속포함']
const best_tb_row6 = ['GPS 간섭유무', '전면용은 영향없음', '영향없음', '영향있음', '영향있음', '영향있음']
const best_tb_row7 = ['하이패스 (IR 타입)', '영향없음', '영향없음', '영향없음', '영향없음', '영향없음']
const best_tb_row8 = ['하이패스 (RF 타입)', '영향없음', '영향없음', '영향있음', '영향있음', '약간영향']
const best_tb_row9 = ['라디오 글라스 안테나', '측후면용 영향있음', '영향없음', '초미세', '초미세', '초미세']
const best_tb_row10 = ['RF 주파수 간섭', '전면용은 영향없음', '영향있음', '약간영향', '약간영향', '약간영향']
const best_tb_row11 = ['스마트폰 와이파이', '전면용은 영향없음', '영향없음', '약간영향', '초미세', '초미세']
const best_tb_row12 = [
  '전파간섭유무',
  '전면용은 전파장애 0%',
  '전파장애 0%',
  '전면썬팅 시 영향 있음',
  '전면썬팅 시 영향 있음',
  '전면썬팅 시 영향 있음',
]
const best_tb_row13 = ['터널 무아레', '측후면 (5/15%) 약간영향', '해당없음', '미세', '초미세', '초미세']

const best_tb = [
  best_tb_row1,
  best_tb_row2,
  best_tb_row3,
  best_tb_row4,
  best_tb_row5,
  best_tb_row6,
  best_tb_row7,
  best_tb_row8,
  best_tb_row9,
  best_tb_row10,
  best_tb_row11,
  best_tb_row12,
  best_tb_row13,
]
class ProductMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scroll_value: 0,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  renderTableData(data) {
    return Object.keys(data).map(index => {
      if (index > 0) {
        return (
          <tr key={index}>
            <td className="first_col">{data[index][0]}</td>
            <td>{data[index][1]}</td>
            <td>{data[index][2]}</td>
            <td>{data[index][3]}</td>
            <td>{data[index][4]}</td>
            <td>{data[index][5]}</td>
            {data[index][6] ? <td>{data[index][6]}</td> : null}
          </tr>
        )
      } else {
        return (
          <tr key={index}>
            <td className="first_col">{data[index][0]}</td>
            <td className="first_row">{data[index][1]}</td>
            <td className="first_row">{data[index][2]}</td>
            <td className="first_row">{data[index][3]}</td>
            <td className="first_row">{data[index][4]}</td>
            <td className="first_row">{data[index][5]}</td>
            {data[index][6] ? <td className="first_row">{data[index][6]}</td> : null}
          </tr>
        )
      }
    })
  }

  render() {
    return (
      <div className="product_mobile">
        <div className="product_upper_section">
          <div className="headline">Why Tinting?</div>
          <div className="why_tinting_container">
            <div className="why_tinting_1">
              <img alt="" className="icon" src={Clearness}></img>
              <div className="title">열차단</div>
              <div className="text">
                야외 주차 후 차에 들어갔더니 너무 뜨거워서 불쾌했던 경험있으시죠?. 그런 날은 에어컨도 오래 틀어도
                시원해질 기미도 없구요. 그러다보니 연비도 나빠지고 운전하는 내내 스트레스를 받게 됩니다. 열차단 틴팅으로
                내부 온도를 쾌적하게 유지할 수 있어요.
              </div>
            </div>
            <div className="why_tinting_2">
              <img alt="" className="icon" src={Number_1}></img>
              <div className="title">자외선 차단</div>
              <div className="text">
                외출하실 때 항상 썬크림 바르시죠? 자외선 차단 기능이 없는 자동차 내부에서는 야외와 동일한 양의 자외선에
                노출됩니다. 자외선은 피부암과 피부 노화의 원인이기도 하지만 차량 내부의 탈색도 유발한답니다. 틴팅으로
                소중한 피부와 차를 보호하세요.
              </div>
            </div>
            <div className="why_tinting_3">
              <img alt="" className="icon" src={UV_block}></img>
              <div className="title">프라이버시</div>
              <div className="text">
                옆차에서 왜 힐끔힐끔 쳐다보는 건지. 길 가는 행인들은 왜 뚫어져라 들여다보는 건지. 차 안에 놔둔 물건이
                훤히 보이는 것도 부담스럽고. 내 차에서 만큼은 좀 편하게 있고 싶으시죠? 틴팅은 타인의 시선으로부터
                해방되는 프라이버시를 보장합니다.
              </div>
            </div>
            <div className="why_tinting_4">
              <img alt="" className="icon" src={Science}></img>
              <div className="title">스타일링</div>
              <div className="text">
                남들과 똑같은 차도 싫고, 똑같은 차 색깔도 싫고, 그렇다면 자동차 유리 색깔도 달라야겠죠? 틴팅 필름이라고
                해서 모두 같은 검정색이 아닙니다. 검정 위로 은은하게 비치는 푸른색, 초록색, 황금색으로 원하는 스타일을
                입혀보세요.
              </div>
            </div>
          </div>
        </div>
        <div className="product_center_section">
          <img alt="" className="main_image" src={How_To_Pick}></img>
          <div className="how_to_pick_container">
            <div className="headline">How to Pick</div>
            <div className="headline_sub">
              틴팅 필름은 성능과 가격 그리고 브랜드를 모두 종합적으로 고려해서 본인의 취향과 예산에 따라서 선택해야
              합니다.
            </div>
            <div
              className="item_container"
              id="scroll_len"
              onScroll={() => {
                var container = window.document.getElementById('scroll_len')
                this.setState({
                  scroll_value: container.scrollLeft,
                })
              }}
            >
              <div className="item">
                <div className="title">01. 가시광선 투과율</div>
                <div className="text">
                  필름의 어둡기를 나타내는 지표로 숫자가 낮을수록 빛 투과가 적게 되서 어둡고 높을수록 빛 투과가 많이
                  되서 밝습니다.
                </div>
              </div>
              <div className="item">
                <div className="title">02. 가시광선 반사율</div>
                <div className="text">
                  필름을 볼 때 얼만큼 거울처럼 반사가 되는지를 나타내는 지표로 높을수록 거울처럼 반사가 되기 때문에
                  시인성을 저해합니다.
                </div>
              </div>
              <div className="item">
                <div className="title">03. 자외선 차단율</div>
                <div className="text">
                  필름을 볼 때 얼만큼 거울처럼 반사가 되는지를 나타내는 지표로 높을수록 거울처럼 반사가 되기 때문에
                  시인성을 저해합니다.
                </div>
              </div>
              <div className="item">
                <div className="title">04. 총태양에너지 차단율</div>
                <div className="text">
                  적외선 및 가시광선을 통해 전달되는 태양열을 얼만큼 차단하는지를 보여주는 지표로 높을수록 많이 열차단을
                  합니다.
                </div>
              </div>
            </div>
            <div className="current_scroll_container">
              {this.state.scroll_value >= 0 && this.state.scroll_value < 200 ? (
                <div className="current_scorll"></div>
              ) : (
                <div className="yet_scorll"></div>
              )}
              {this.state.scroll_value >= 200 && this.state.scroll_value < 400 ? (
                <div className="current_scorll"></div>
              ) : (
                <div className="yet_scorll"></div>
              )}
              {this.state.scroll_value >= 400 && this.state.scroll_value < 600 ? (
                <div className="current_scorll"></div>
              ) : (
                <div className="yet_scorll"></div>
              )}
              {this.state.scroll_value >= 600 ? (
                <div className="current_scorll"></div>
              ) : (
                <div className="yet_scorll"></div>
              )}
            </div>
          </div>
        </div>
        <div className="product_lower_section">
          <div className="premium_container">
            <div className="premium_headline">최고급 제품 라인업</div>
            <div className="premium_table">
              <table>
                <tbody>{this.renderTableData(premium_tb)}</tbody>
              </table>
            </div>
          </div>
          <div className="best_container">
            <div className="best_headline">고급 제품 라인업</div>
            <div className="best_table">
              <table>
                <tbody>{this.renderTableData(best_tb)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductMobile
